import { cx } from '@emotion/css'
import { MenuItem as OriginMenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  red: {
    color: theme.palette.error.main,
  },
  link: {
    color: '#000000',
    textDecoration: 'none',
  },
}));

const MenuItem = (props) => {
  const { children, red, href, ...restProps } = props;
  const {classes, cx} = useStyles();

  return (
    <a href={href} className={classes.link}>
      <OriginMenuItem
        {...restProps}
        classes={{
          root: cx(
            classes.menuItem,
            red ? classes.red : '',
          ),
        }}
      >
        {children}
     </OriginMenuItem>
    </a>
  );
};

MenuItem.defaultProps = {
  href: '#',
};

export default MenuItem;
